import { Component } from 'vue-property-decorator'
import { FinancialView } from '@/components/forms/view/FinancialView'

@Component
export class AppraisalView extends FinancialView {
  statusDeal = null
  statusAuto = null
  statusProcessAppraisal = null
  statusAppraisal = {
    noOffer: null,
    appealed: null,
    appraised: null,
  }

  km = null
  owner = null

  async mounted () {
    const filter = {
      _and: [
        { process: { table_name: { _eq: 'appraisal' } } },
        { status: { name: { _eq: 'no_offer' } } },
      ],
    }
    this.statusAppraisal.noOffer = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter,
    })

    this.statusAppraisal.appealed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'appraisal' } } },
          { status: { name: { _eq: 'appealed' } } },
        ],
      },
    })

    this.statusAppraisal.appraised = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'appraisal' } } },
          { status: { name: { _eq: 'appraised' } } },
        ],
      },
    })

    this.statusDeal = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'deal' } } }, { status: { name: { _eq: 'active' } } }] },
    })

    this.statusAuto = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'auto' } } }, { status: { name: { _eq: 'new' } } }] },
    })

    this.statusProcessAppraisal = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'appraisal' } },
    })

    this.km = await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: { slug: { _eq: 'mileage' } },
    })

    this.owner = await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: { slug: { _eq: 'owners_number' } },
    })
  }

  async insertUpdateFile (form, id) {
    const { idProcess, fields, cav, document, photos } = form
    const {
      cav: cavField, document: documentField, photos: photosField,
    } = fields
    await this.handleFileType(cav, cavField, idProcess, id)
    await this.handleFileType(document, documentField, idProcess, id)
    await this.handleFileType(photos, photosField, idProcess, id)
  }

  goToOfficialSite (appraisal) {
    if (!appraisal.deal?.auto?.officialSite) return null
    window.open(appraisal.deal?.auto?.officialSite, '_blank')
  }

  async buildPurchase (viewData) {
    const { backup, lead: { purchase }, formData: data } = viewData

    if (purchase?.id) {
      return {
        id: purchase.id,
        id_channel: data.channel.id,
        id_intention: data.intention?.id,
      }
    }

    if (!backup?.auto || !backup?.appraisalForm) {
      return {}
    }
    const { auto, appraisalForm } = backup

    const autoId = auto?.id || appraisalForm?.auto?.id

    const buildAuto = autoId ? { id_auto: autoId } : {
      auto: {
        data: {
          id_process_status: this.statusAuto?.[0]?.id,
          id_version_year: auto?.generation?.id ? undefined : auto?.version?.id,
          registration_plate: auto.registrationPlate,
          id_generation: auto?.generation?.id,
        },
      },
    }
    const basic = {
      id_channel: data.channel.id,
      id_intention: data.intention?.id,
      id_process_status: this.statusDeal?.[0]?.id,
      id_referrer: data.referred?.id,
      type: 'purchase',
      notes: {
        data: [{ note: data.note }],
      },
      ...buildAuto,
      auto_attributes: {
        data: [
          { id_component: this.owner[0]?.id, id_component_value: appraisalForm?.owner },
          { id_component: this.km[0]?.id, value: appraisalForm?.kms },
        ].filter(_ => _.value || _.id_component_value),
      },
      appraisals: {
        data: [{
          id_process_status: this.statusAppraisal.noOffer?.[0]?.id,
          external_offers: {
            data: [
              { id_person: appraisalForm.companies.offer1.id, amount: appraisalForm.offer1 },
              { id_person: appraisalForm.companies.offer2.id, amount: appraisalForm.offer2 },
            ].filter(_ => _.amount),
          },
          responses: {
            data: [
              {
                amount: appraisalForm.price,
                type: 'expected_offer',
                id_employee: this.idEmployee,
                comment: appraisalForm.comment,
              },
            ].filter(_ => _.amount),
          },
        }],
      },
    }
    if (appraisalForm?.maintenance?.length) {
      const maintenances = await this.buildMaintenances(appraisalForm)
      if (basic?.auto?.data) {
        Object.assign(basic.auto.data, { ...basic.auto.data, maintenances })
      }
    }

    if (!basic.appraisals.data[0].external_offers.data.length) {
      delete basic.appraisals.data[0].external_offers
    }

    if (!basic.notes.data[0].note) {
      delete basic.notes
    }
    if (!basic?.auto_attributes?.data?.length) {
      delete basic.auto_attributes
    }
    if (!basic.id_referrer) {
      delete basic.id_referrer
    }
    if (basic?.appraisals?.data.length && !basic?.appraisals?.data[0]?.responses?.data?.length) {
      delete basic.appraisals.data[0].responses
    }

    return { ...basic }
  }

  async buildMaintenances (appraisalForm) {
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { status: { name: { _eq: 'done' } } },
    })
    const maintenances = {
      data: appraisalForm.maintenance.map(maintenance => {
        return { mileage: maintenance.mileage, id_maintenance_type: maintenance.id, id_process_status: status[0].id }
      }),
    }

    if (appraisalForm?.prepaidMaintenance?.length) {
      const status = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { status: { name: { _eq: 'prepaid' } } },
      })
      const prepaid = appraisalForm.prepaidMaintenance.map(maintenance => {
        return { mileage: maintenance.mileage, id_maintenance_type: maintenance.id, id_process_status: status[0].id }
      })
      maintenances.data = [...maintenances.data, ...prepaid]
    }

    return maintenances
  }

  async createAppraisal (viewData) {
    const { lead, lead: { sale }, formData, backup } = viewData

    const channel = lead?.channel || sale?.channel
    const intention = lead?.intention || sale?.intention || backup?.formData?.intention?.id || backup?.formData?.intention
    const referred = lead?.referred || sale?.referred
    const purchase: Record<string, any> = await this.buildPurchase({
      lead: {},
      formData: {
        channel,
        intention,
        referred,
        note: '',
      },
      backup: { appraisalForm: formData, auto: formData.auto },
    })

    const deal = await this.pushData({
      model: 'Deal',
      fields: { ...purchase, id_lead: lead.id },
    })

    await Promise.all(deal.autoAttributes.map(async attribute => {
      await this.pushData({
        model: 'DealAutoAttribute',
        fields: {
          id: attribute?.id,
          id_process_record: deal.appraisal.id,
          id_process: this.statusProcessAppraisal?.[0]?.id,
        },
      })
    }))
    await this.automaticAppraisal(deal.appraisal.id)
  }

  async sendNewAppraisal (id, dealId, formData) {
    const deal = await this.fetchData({ query: { name: 'find', model: 'Deal' }, filter: { appraisals: { id: { _eq: id } } } })

    const newForm = formData
    newForm.cav = []
    newForm.agreementPrice = null
    const purchase: Record<string, any> = await this.buildPurchase({
      lead: {},
      formData: {
        channel: { ...deal[0].channel },
        intention: { ...deal[0].intention },
        referred: { ...deal[0].referred },
        note: '',
      },
      backup: { appraisalForm: newForm, auto: {} },
    })

    const newAppraisal = purchase.appraisals.data[0]

    const appraisal = await this.pushData({
      model: 'Appraisal',
      fields: { ...newAppraisal, id_deal: dealId },
    })

    await Promise.all(purchase.auto_attributes.data.map(async attribute => {
      await this.pushData({
        model: 'DealAutoAttribute',
        fields: {
          id_process_record: appraisal.id,
          id_process: this.statusProcessAppraisal?.[0]?.id,
          ...attribute,
          id_deal: dealId,
        },
      })
    }))
    await this.automaticAppraisal(appraisal.id)
  }

  async sendNewDeal (appraisalView) {
    const { formData, appraisal: { deal } } = appraisalView

    const dealInfo = await this.fetchData({ query: { name: 'fetch', model: 'Deal', params: { id: deal.id } } })

    const purchase: Record<string, any> = await this.buildPurchase({
      lead: {},
      formData: {
        channel: { ...dealInfo.channel },
        intention: { ...dealInfo.intention },
        referred: { ...dealInfo.referred },
        note: '',
      },
      backup: { appraisalForm: formData, auto: formData.auto },
    })

    const newDeal = await this.pushData({
      model: 'Deal',
      fields: { ...purchase, id_lead: deal.lead.id },
    })

    await Promise.all(purchase.auto_attributes.data.map(async attribute => {
      await this.pushData({
        model: 'DealAutoAttribute',
        fields: {
          id_process_record: newDeal.appraisal.id,
          id_process: this.statusProcessAppraisal?.[0]?.id,
          ...attribute,
          id_deal: newDeal.id,
        },
      })
    }))

    await this.automaticAppraisal(newDeal.appraisal.id)
  }

  async updateDealAutoAttribute (idAppraisal, idAttribute) {
    await this.pushData({
      model: 'DealAutoAttribute',
      fields: {
        id_process_record: idAppraisal,
        id: idAttribute,
        id_process: this.statusProcessAppraisal?.[0]?.id,
      },
    })
  }
}
