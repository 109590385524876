import { Component } from 'vue-property-decorator'
import { parseToNumber } from '@/utils/general'
import { AppraisalView } from '@/components/forms/view/AppraisalView'

@Component
export class LeadView extends AppraisalView {
  statusLead = null
  statusSaleOrder = null
  employeeStatus = {
    active: null,
  }

  async mounted () {
    this.statusLead = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'lead' } } }, { status: { name: { _eq: 'active' } } }] },
    })
    this.statusSaleOrder = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'pending' } } }] },
    })

    this.employeeStatus.active = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'employee' } } }, { status: { name: { _eq: 'active' } } }] },
    })
  }

  async createLead (viewData, automaticAssignment = false) {
    const { backup } = viewData
    const lead = await this.buildData(viewData, automaticAssignment)
    const newLead = await this.pushData({ model: 'Lead', fields: { ...lead } })

    if (newLead?.purchase) {
      const { purchase: { autoAttributes, appraisal: { id: idAppraisal } }, purchase } = newLead

      if (autoAttributes?.length) {
        await this.updateAttributes(autoAttributes, purchase)
      }
      const { appraisalForm } = backup
      await this.insertUpdateFile(appraisalForm, idAppraisal)
    }
    if (newLead?.sale) {
      const { sale: { order: { saleOrderItems, financing } } } = newLead
      if (financing?.id) {
        await this.insertFinancedItem(saleOrderItems, financing, backup.financialForm)
      }
    }

    if (newLead?.purchase) {
      await this.callAutomaticAppraisalAction(newLead.purchase)
    }
  }

  async callAutomaticAppraisalAction (purchase) {
    await this.automaticAppraisal(purchase.appraisal.id)
  }

  async updatePurchase (viewData) {
    const { lead, formData } = viewData

    if (lead?.purchase?.id) {
      await this.pushData({
        model: 'Deal',
        fields: {
          id: lead?.purchase?.id,
          id_intention: formData?.intention?.id || formData?.intention,
        },
      })
    }
  }

  async editLead (viewData) {
    const { lead, formData: data, forwarder } = viewData
    const { id, sale: leadSale, computedPipeline } = lead

    await this.insertUpdateSale(leadSale, viewData, id)
    await this.updatePurchase(viewData)
    const fields = {
      id,
      id_pipeline: computedPipeline,
      id_executive: data?.executive?.employee?.id || this.idEmployee,
      id_forwarder: this.idEmployee,
      metadata: {
        note: data.note,
      },
    }

    if (!forwarder) {
      delete fields.id_forwarder
    }

    await this.pushData({
      model: 'Lead',
      fields,
    })
  }

  async editLeadExecutive (viewData) {
    const { lead, formData: data, forwarder } = viewData
    const { id } = lead

    const fields = {
      id,
      id_executive: data?.executive?.employee?.id || this.idEmployee,
      id_forwarder: this.idEmployee,
    }

    if (!forwarder) {
      delete fields.id_forwarder
    }

    await this.pushData({
      model: 'Lead',
      fields,
    })
  }

  async insertUpdateSale (leadSale, viewData, id) {
    const { formData } = viewData
    if (!formData?.stock) return
    const sale = await this.buildSale(viewData)

    if (Object.keys(sale).length > 1) {
      if (leadSale?.id) {
        await this.pushData({ model: 'Deal', fields: { ...sale } })
      } else {
        const fields = { ...sale, id_lead: id }
        await this.pushData({ model: 'Deal', fields })
      }
    }
  }

  async buildSale (viewData) {
    const { total, stock, lead: { sale } } = viewData
    const stockData = stock || sale?.stock

    if (!stockData) return {}

    if (sale?.id) {
      return this.handleExistingSale(sale, viewData)
    }

    const basic = this.buildBasicObject(viewData)
    const orders = await this.buildOrdersObject(total, stockData, viewData)

    return { ...basic, ...orders }
  }

  async buildOrdersObject (total, stock, viewData) {
    const { backup, formData } = viewData
    const discountTypes = await this.fetchData({
      query: { name: 'find', model: 'ItemType' },
      filter: {},
    })
    const orders = {
      sale_orders: {
        data: {
          financings: {
            data: [{
              evaluations: {
                data: await this.getEvaluationObject(),
              },
              id_applicant: backup?.financialForm?.applicant?.id,
              initial: Math.round(parseToNumber(backup?.financialForm?.pie?.amount)),
              warranty_amount: total,
            }],
          },
          sale_order_items: {
            data: [
              {
                id_item_type: discountTypes?.find(item => item.name === 'product')?.id,
                quantity: 1,
                amount: stock?.price?.amount,
              },
              {
                id_item_type: discountTypes?.find(item => item.name === 'discount')?.id,
                quantity: 1,
                amount: formData?.discount,
              },
              {
                id_item_type: discountTypes?.find(item => item.name === 'transfer')?.id,
                quantity: 1,
                amount: stock?.transferCost,
              },
            ].filter(_ => _.amount),
          },
          id_process_status: this.statusSaleOrder?.[0]?.id,
        },
      },
    }

    if (!backup?.financialForm?.pie?.amount) {
      delete orders.sale_orders.data.financings
    }

    return orders
  }

  buildBasicObject (viewData) {
    const { formData: data, lead } = viewData
    const basic = {
      id_auto: data.stock?.id,
      id_channel: data.channel?.id,
      id_intention: data?.intention?.id || data?.intention,
      id_referrer: data.referred?.id,
      id_process_status: this.statusDeal?.[0]?.id,
      id_stock: lead.sale.stock?.id,
      type: 'sale',
      notes: {
        data: [{ note: data.note }],
      },
    }

    if (!basic.notes.data[0].note) {
      delete basic.notes
    }

    if (!basic.id_referrer) {
      delete basic.id_referrer
    }

    return basic
  }

  async handleExistingSale (sale, viewData) {
    const { formData: data, stock } = viewData
    const stockData = stock || sale?.stock
    const basic = {
      id: sale.id,
      id_channel: sale?.channel?.id !== data.channel?.id ? data.channel?.id : null,
      id_intention: sale?.intention?.id !== data.intention?.id ? data.intention?.id : null,
    };

    ['id_channel', 'id_intention'].forEach(key => {
      if (!basic[key]) delete basic[key]
    })

    const { order: { discount, id, saleOrderItems } } = sale

    if ((!discount && data?.discount) || ((discount?.value && data?.discount) && (discount?.value !== Number(data?.discount)))) {
      await this.insertUpdateSalesOrderItemDiscount(data, id, saleOrderItems)
    }

    if (!saleOrderItems?.find(item => item.isProduct)) {
      await this.insertSalesOrderItemProduct(id, stockData)
    }

    if (!saleOrderItems?.find(item => item.isTransfer)) {
      await this.insertSalesOrderItemTransfer(id, stockData)
    }

    return basic
  }

  async buildDeals (viewData) {
    const sale = await this.buildSale(viewData)

    const purchase = await this.buildPurchase(viewData)
    return { data: [{ ...sale }, { ...purchase }].filter(_ => Object.keys(_).length) }
  }

  async buildData (viewData, automaticAssignment = false) {
    const { lead: { computedPipeline: pipeline }, formData: data, forwarder, idEmployee } = viewData
    const executiveId = await this.getExecutiveByPerson(data.client.id)
    const executive = automaticAssignment ? data?.executive?.employee?.id || executiveId : idEmployee

    const basic = {
      id: undefined,
      id_executive: executive,
      id_person: data.client.id,
      id_channel: data.channel.id,
      id_forwarder: forwarder ? this.idEmployee : undefined,
      id_process_status: this.statusLead?.[0]?.id,
      id_pipeline: pipeline,
      metadata: {
        note: data.note,
      },
    }
    const deals = await this.buildDeals(viewData)

    return { ...basic, deals }
  }

  async insertUpdatePurchase (leadPurchase, viewData, id) {
    if (!viewData?.formData?.appraisal) return
    const purchase = await this.buildPurchase(viewData)

    let response
    if (leadPurchase?.id) {
      response = await this.pushData({ model: 'Deal', fields: { ...purchase } })
    } else {
      const fields = { ...purchase, id_lead: id }
      response = await this.pushData({ model: 'Deal', fields })
    }

    const { autoAttributes, appraisal } = response

    if (autoAttributes?.length) {
      await this.updateAttributes(autoAttributes, { appraisal })
    }
  }

  async updateAttributes (attributes, { appraisal: { id: idAppraisal } }) {
    const km = attributes.find(attribute => attribute.slug === 'mileage')
    const owner = attributes.find(attribute => attribute.slug === 'owners_number')

    if (km) {
      await this.updateDealAutoAttribute(idAppraisal, km.id)
    }
    if (owner) {
      await this.updateDealAutoAttribute(idAppraisal, owner.id)
    }
  }

  async fetchLead (id) {
    return this.fetchData({
      query: { name: 'fetch', model: 'Lead', params: { id } },
      force: true,
    })
  }

  async fetchDealSale (id) {
    return this.fetchData({
      query: { name: 'fetch', model: 'Deal', params: { id } },
      force: true,
    })
  }
}
